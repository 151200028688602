import React from "react";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Slider from "../components/Slider";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Event = () => {
  const videos = [
    "/videos/oxygen_video.mp4",
    "/videos/W.mp4",
    "/videos/kpsolla.mp4",
  ];
  return (
    <div className="">
      <Header />
      <div>
        <img src="/Hero_Portal.jpg" className="w-full block" alt="" />
      </div>

      <div className="flex flex-col items-center justify-center mt-24 ">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 last:rid-col-start-3 place-items-center grid-cols-1 gap-6 gap-x-10 mx-10 sm:mx-2">
          <div>
            <h3 className="text-center">Oxygen Services</h3>
            <video preload="metadata" controls className="w-[30rem]   block">
              <source src="/videos/W.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <h3 className="text-center">Kpsolla Platform</h3>
            <video preload="metadata" controls className="w-[30rem]   block">
              <source src="/videos/kpsolla.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="md:grid-col-start-3 md:grid-col-end-3 md:col-span-2 lg:col-span-1">
            <h3 className="text-center">Smart Center</h3>
            <video preload="metadata" controls className="w-[30rem]   block">
              <source src="/videos/oxygen_video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <a
          href="/files/Oxygen_Profile- last update.pdf"
          download
          className="flex  items-center bg-primary rounded-sm p-2 my-14 justify-between   text-white">
          <span className="mr-2">Download</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </a>
      </div>
      <div className="">
        <img src="/Hero_Oxygen.jpg" className="w-full block" />
      </div>
      <div className="min-h-[40vh] p-10">
        <h1 className="font-bold text-xl md:text-5xl mb-8">Contact us</h1>
        <div className="flex child:mr-4 mb-4">
          {/* <FontAwesomeIcon icon="fa-brands fa-linkedin-in" /> */}
          <a
            href="https://www.linkedin.com/company/oxygen-technologies-ltd/"
            target="_blank"
            rel="noreferrer"
            className="rounded  block-inline  p-3 cursor-pointer bg-primary text-white hover:bg-black">
            <i className="fa-brands fa-linkedin "></i>
          </a>
          <a
            href="https://www.facebook.com/Oxygenict/"
            target="_blank"
            rel="noreferrer"
            className="rounded  block-inline  p-3 cursor-pointer bg-primary text-white hover:bg-black">
            <i className="fa-brands fa-facebook"></i>
          </a>
        </div>
        <div className="child:mb-4 ">
          <div>
            <i class="fa-solid fa-location-dot mr-2"></i>Sudan, khartoum,
            Almasnshiya -Al-Doha st
          </div>
          <div>
            <i class="fa-solid fa-envelope mr-2"></i>info@oxygenict.com
          </div>
          <div>
            <i class="fa-solid fa-phone mr-2"></i>3490 - 0120093536
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Event;
