import React from "react";
// import ReactElasticCarousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import Article from "../components/Article";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MemberCard from "../components/MemberCard";
import "./About.css";

const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Header />

      {/* <Hero /> */}

      <div
        id="hero"
        className="min-h-[50vh] hero  text-indigo-200 flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md">
          <h2
            className=" drop-shadow-2xl capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            {t("about-hero")}
          </h2>
        </div>
      </div>
      <section
        id="section"
        className="flex justify-center flex-col items-center mt-4">
        <Article
          title={t("about-who-are-we")}
          short={t("about-who-are-we-short")}
          content={t("about-who-are-we-content")}
        />
        <Article
          title={t("about-what-drives-us")}
          short={t("about-what-drives-us-short")}
          content={t("about-what-drives-us-content")}
        />
        <Article
          title={t("about-oxygen-values")}
          short={t("about-oxygen-values-short")}
          content={t("about-oxygen-values-content")}
        />
        <Article
          title={t("about-oxygen-mission")}
          short={t("about-oxygen-mission-short")}
          content={t("about-oxygen-mission-content")}
        />
      </section>
      <section className=" hidden justify-center flex-col items-center">
        <h2 className="text-7xl text-center  my-4">our team</h2>
        <div className="lg:w-[60rem] mb-6 md:w-[40rem] w-[20rem]">
          {/* <ReactElasticCarousel
            showArrows={false}
            // enableAutoPlay
            breakPoints={[
              { width: 1200, itemsToShow: 1 },
              { width: 600, itemsToShow: 3, itemsToScroll: 1 },
            ]}>
            <MemberCard
              title="GM"
              desc="empowering leadership is the key to our success by which we guarantee that business is running smoothly and successfully

              a senior manager who is responsible for overseeing the activities of an entire company and gets involved in tasks daily pattern,"
              img="https://www.w3schools.com/w3images/avatar1.png"
            />
            <MemberCard
              title="project managers"
              desc=" We believe that being a Project Manager is like being an
                  artist, you have the different colored process streams
                  combining into a work of art. Adapt to the changing nature of
                  project management and be comfortable in every change."
              img="https://www.w3schools.com/w3images/avatar6.png"
            />
            <MemberCard
              title="hr"
              desc="While company leaders and front-line managers support
                  employees and help them accomplish their goals, HR takes a
                  holistic view of how to ensure the company has the talent
                  required for the organization"
              img="https://www.w3schools.com/w3images/avatar5.png"
            />
            <MemberCard
              title="Developer’s team"
              desc="in oxygen we have a distinguished team of software developers
                  Software developers are the brainstorming masterminds behind
                  our activities of all sorts."
              img="https://www.w3schools.com/w3images/avatar4.png"
            />
            <MemberCard
              title="Marketing team"
              desc="A good salesperson has more to offer to customers than an
                  exciting pitch Loyalty to our customers and meeting their
                  expectations is our number one goal."
              img="https://www.w3schools.com/w3images/avatar2.png"
            />
            <MemberCard
              title="Designers"
              desc="Oxygen have creative professionals who develops visual content
                  assets for digital and print mediums. They can work on a
                  variety of projects, including advertisements, digital media,
                  web and application design and other web services."
              img="https://www.w3schools.com/w3images/avatar3.png"
            />
            <MemberCard
              title="Accountant"
              desc="Working as an accountant involves paying close attention to
                  detail. Accountant has an eye for detail and the ability to
                  quickly find information we need being comfortable working
                  with software and other tech tools."
              img="https://www.w3schools.com/w3images/avatar6.png"
            />
            <MemberCard
              title="Operation manager"
              desc="  A functional and well-run operations team relies on great
                  support from the top down. Operation team with good management
                  and Analytical skills with the ability to collect and analyze
                  information."
              img="https://www.w3schools.com/w3images/avatar5.png"
            />
          </ReactElasticCarousel> */}
        </div>
        {/* <div className="max-w-[60rem] my-4 mx-4">
          <img
            className="w-full"
            src="https://www.researchgate.net/profile/Hazeline-Ayoup/publication/281605381/figure/fig1/AS:340510473506821@1458195431541/Company-XYZ-Organisational-Structure.png"
            alt=""
          />
        </div> */}
      </section>
      <Footer />
    </div>
  );
};

export default About;
