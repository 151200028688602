import React from "react";
import { useTranslation } from "react-i18next";

import "./Hero.css";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div
      className=" min-h-screen flex items-center flex-col justify-center  hero"
      style={{
        backgroundImage:
          " linear-gradient(115deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('J4x.gif')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* <video
        loop
        src="/4K technology free moving background globe animation.mp4"
        // className="h-screen w-screen"
        autoPlay
      ></video> */}
      <div className="text-white md:max-w-2xl max-w-xs">
        <h1
          style={{ textShadow: "#6E8AFC 10px 10px 20px" }}
          className="text-white text-center text-xl md:text-4xl shadow font-bold"
        >
          {t("home-hero-short")}
        </h1>
        <p
          style={{ textShadow: "#6E8AFC 10px 10px 20px" }}
          className="text-center text-lg md:text-2xl mt-8"
        >
          {t("home-hero-long")}
        </p>
      </div>
    </div>
  );
};

export default Hero;
