import React, { useState } from "react";

import Popover from "@mui/material/Popover";

const TImeCapsule = ({ title, time, content }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div className="box">
        <div className="date-box">
          <span>{time}</span>
        </div>
        <div className="info-box">
          <h1
            className="md:hidden text-md block cursor-pointer"
            onClick={handleClick}>
            {title}
          </h1>
          <div className="tooltip !hidden  md:!inline-block">
            <h1>{title}</h1>
            <div className="bottom shadow shadow-primary">
              {/* <h3 className="text-xl font-bold text-primary">{title}</h3> */}
              <div className="text-md font-bold">{content}</div>
            </div>
          </div>
        </div>
      </div>
      <Popover
        // id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          padding: "10px 20px",
          minWidth: "200px !important",
        }}
        classes={{ paper: "!shadow !shadow-primary px-2 py-5 !w-[400px]" }}>
        <p className="text-md font-bold">{content}</p>
      </Popover>
    </>
  );
};

export default TImeCapsule;
