import React from "react";

import { Link, NavLink } from "react-router-dom";

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  return (
    <aside
      className={`top-0 left-0 w-full transition-all ease  bg-white duration-500  text-white fixed h-full z-20 ${
        showSidebar
          ? "-translate-x-0 opacity-100"
          : "-translate-x-full opacity-0"
      }`}>
      <div className=" bg-primary  flex h-24 justify-center items-center hover:bg-black transition-all duration-300">
        <Link to="/contact">
          <h1 className=" text-white   font-bold capitalize mt-2 cursor-pointer   flex  justify-center items-center">
            contact us
          </h1>
        </Link>
      </div>
      <div className="text-black child:block child:capitalize mt-3 ml-3 child:mb-1 child:p-1 transition-all duration-200 child-hover:text-primary child:w-full">
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FEC906] text-xl" : ""
          }
          to="/">
          Home
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FEC906] text-xl" : ""
          }
          to="/about">
          about us
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FEC906] text-xl" : ""
          }
          to="/history">
          our history
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FEC906] text-xl" : ""
          }
          to="/achievements">
          achievements
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FEC906] text-xl" : ""
          }
          to="/event">
          event
        </NavLink>
      </div>
    </aside>
  );
};

export default Sidebar;
