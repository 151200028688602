import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Partners from "../components/Partners";
import Services from "../components/Services";
const Home = () => {
  return (
    <div>
      {" "}
      <Header />
      <Hero />
      <Services />
      <Partners />
      <Footer />
    </div>
  );
};

export default Home;
