import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Achievements = () => {
  return (
    <div>
      <Header bg={true} />
      <div className="hero min-h-[50vh]" />
      <section>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
          excepturi deleniti libero sint totam eius architecto officia ipsam
          voluptatem error, molestiae asperiores omnis rem ab consectetur amet
          corporis magnam! Error.
        </p> */}
        <div>
          {" "}
          <img src="/award.jpg" alt="award" />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Achievements;
