import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Education = () => {
  //   const [isExpanded, setIsExpanded] = useState(false);

  //   const handleClick = () => {
  //     setIsExpanded(!isExpanded);
  //   };
  return (
    <div>
      <Header />
      <div className="min-h-[50vh] hero text-indigo-200 flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md">
          <h2
            className=" drop-shadow-2xl capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            Education
          </h2>
        </div>
      </div>
      <main className=" min-h-screen flex sm:block mt-4 flex-col items-center">
        {/* kspolla */}
        <article className="border shadow-indigo-200 shadow-xl mb-8 m-2 p-4 md:max-w-5xl lg:max-w-full lg:w-[90rem] max-w-md">
          <div className="w-28">
            <img src="/kpsolla-logo.png" alt="logo" className="w-full" />
          </div>

          <div className={` tracking-wide`}>
            <p>
              Kpsolla is an online education provider, that offers an online
              courses,
            </p>
            <div className="flex">
              <p>
                popularly known as MOOC or Massive Open Online Courses, From
                pioneers’ professional trainers, institutes and companies around
                the world.Kpsolla employs technology to facilitate, enhance and
                Expand educational content access and tracking of training
                progress.Currently, Kpsolla boasts an active catalog of more
                than online courses created by OXYGEN Co. Kpsolla is Sudanese
                Platform founded in 2020, by OXYGEN for ICT solutions.{" "}
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-end">
              <div className="lg:w-[40rem] mt-4 ">
                <img src="/kpsolla.jpg" alt="kpsolla" className="w-full" />
              </div>
              <a
                href="https://www.kpsolla.com/"
                rel="noreferrer"
                target="_blank"
                className="text-primary hover:text-indigo-200 block  w-96 text-2xl ml-4">
                visit the website!
              </a>
            </div>
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default Education;
