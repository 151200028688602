import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Telecommunication = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <Header />
      <div className="min-h-[50vh] hero text-indigo-200 flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md">
          <h2
            className=" drop-shadow-2xl capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            Telecommunication
          </h2>
        </div>
      </div>
      <main className=" min-h-screen flex sm:block mt-4 flex-col items-center">
        {/* call center */}
        <article className="border shadow-indigo-200 shadow-xl mb-8 m-2 p-2 md:max-w-5xl lg:w-[90rem] max-w-md">
          <h2 className="text-3xl font-bold capitalize text-primary">
            call center
          </h2>

          <div className={`readmore tracking-wide ${isExpanded && "expand"}`}>
            <p>
              Due to the continues work and development in technology and to
              fulfill partners and customers needs Oxygen Technologies offers
              virtual call center services ( smart center )
            </p>
            <div className="flex">
              <p>
                with variety of choices to match customer demand. A call center
                phone system is an important tool to establish and maintain
                effective communication channels with customers, suppliers, and
                various agencies connected to your business.
              </p>
            </div>
            <span
              className={`readmore-link text-primary ${isExpanded && "expand"}`}
              onClick={handleClick}></span>
          </div>
          <div>
            <img src="/cloud_Call.gif" alt="call center" />
          </div>
        </article>
        {/* vas sms */}
        <article className="border shadow-indigo-200 shadow-xl mb-8 m-2 p-2 md:max-w-5xl lg:w-[90rem] max-w-md">
          <h2 className="text-3xl font-bold capitalize text-primary">
            Vas , SMS, IVR and integrated activities
          </h2>

          <div className={` tracking-wide my-5`}>
            <p>
              Oxygen present several SMS and IVR services in deferent categories
              including news sports entertainment human development
            </p>
          </div>
          <div>{/* <img src="/call_center.jpg" alt="call center" /> */}</div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default Telecommunication;
