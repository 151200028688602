import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="hero min-h-screen flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md max-w-xs">
          <h2
            className=" drop-shadow-2xl text-indigo-200 text-center capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            AI technologies for efficient and more intelligent products and
            solutions
          </h2>
        </div>
      </div>
      <div className="min-h-[50vh] p-10">
        <h1 className="font-bold text-xl md:text-5xl mb-8">Contact us</h1>
        <div className="flex child:mr-4 mb-4">
          {/* <FontAwesomeIcon icon="fa-brands fa-linkedin-in" /> */}
          <a
            href="https://www.linkedin.com/company/oxygen-technologies-ltd/"
            target="_blank"
            rel="noreferrer"
            className="rounded  block-inline  p-3 cursor-pointer bg-primary text-white hover:bg-black">
            <i className="fa-brands fa-linkedin "></i>
          </a>
          <a
            href="https://www.facebook.com/Oxygenict/"
            target="_blank"
            rel="noreferrer"
            className="rounded  block-inline  p-3 cursor-pointer bg-primary text-white hover:bg-black">
            <i className="fa-brands fa-facebook"></i>
          </a>
        </div>
        <div className="child:mb-4 ">
          <div>
            <i class="fa-solid fa-location-dot mr-2"></i>Sudan, khartoum,
            Riyadh, 112st
          </div>
          <div>
            <i class="fa-solid fa-envelope mr-2"></i>info@oxygenict.com
          </div>
          <div>
            <i class="fa-solid fa-phone mr-2"></i>00249912731811
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
