import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import MenuToggle from "./MenuToggle";
import Sidebar from "./Sidebar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Header = ({ bg }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [background, setBackground] = useState("bg-transparent");
  const { open, setOpen } = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackground("bg-black");
      } else {
        setBackground("bg-transparent");
      }
    });
  }, [bg]);
  const { t, i18n } = useTranslation();
  return (
    <header
      dir="ltr"
      className={`flex items-center justify-between  fixed top-0 text-white w-full font-bold  z-50 border-b p-4 ${background}`}>
      <Link
        to="/"
        className="p-2 md:w-40 w-28 block capitalize cursor-pointer ">
        <img
          src="/Oxygen_LogoWhite.png"
          className="w-full object-contain "
          alt=""
        />
      </Link>
      <Sidebar showSidebar={showSidebar} />
      <nav
        dir={i18n.dir()}
        className="lg:flex hidden w-50 text-xl capitalize  child-hover:scale-105 child:shadow-white child:text-shadow-xl child-hover:text-[#155177]">
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FFC904] text-xl" : ""
          }
          to="/">
          {t("nav-home")}
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FFC904] text-xl" : ""
          }
          to="/about">
          {t("nav-about")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FFC904] text-xl" : ""
          }
          to="/history">
          {t("nav-history")}
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FFC904] text-xl" : ""
          }
          to="/achievements">
          {t("nav-achievements")}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-[#FFC904] text-xl" : ""
          }
          to="/event">
          {t("event")}
        </NavLink>
      </nav>
      {/* <MenuIcon className="h-8 w-8 cursor-pointer" /> */}

      <div className="flex justify-between items-center " dir="ltr">
        <Dropdown open={open} setOpen={setOpen} />

        <Link to="/contact">
          <button className="bg-primary rounded px-4 hidden lg:block hover:ripple-bg-indigo-200  font-bold capitalize p-2 shadow">
            {t("nav-contact")}
          </button>
        </Link>
        <MenuToggle isOpen={showSidebar} setIsOpen={setShowSidebar} />
      </div>
    </header>
  );
};

export default Header;
