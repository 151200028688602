import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import CustomCursor from "./components/CustomCursor";
import About from "./pages/About";
import Achievements from "./pages/Achievements";
import Contact from "./pages/Contact";
import Event from "./pages/Event";
import History from "./pages/History";
import Home from "./pages/Home";
import Advertisment from "./pages/Products/Advertisment";
import DigitalSolution from "./pages/Products/DigitalSolutions";
import Education from "./pages/Products/Education";
import Telecommunication from "./pages/Products/Telecommunication";

function App() {
  const { i18n } = useTranslation();

  // document.body.dir = i18n.dir();

  return (
    <div className="App">
      {/* {window.innerWidth > 700 && <CustomCursor />} */}

      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/event" element={<Event />} />
          <Route path="/history" element={<History />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/services/education" element={<Education />} />
          <Route path="/services/advertisment" element={<Advertisment />} />
          <Route
            path="/services/telecommunication"
            element={<Telecommunication />}
          />
          <Route
            path="/services/digital_solutions"
            element={<DigitalSolution />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
