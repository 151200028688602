import React from "react";
import Header from "../components/Header";
import TImeCapsule from "../components/TImeCapsule";
import "./History.css";

const History = () => {
  return (
    <div className="history">
      <Header bg="bg-black" />
      <div className="min-h-[50vh] hero" />
      <div style={{ position: "relative" }}>
        <div className="container">
          <h1 className="text-md font-bold md:text-xl">
            Oxygen technologies Timeline
          </h1>
          <TImeCapsule
            title={
              <div className="md:flex items-center block">
                <i className="fa-solid fa-phone-volume mr-2 hidden md:block text-4xl"></i>{" "}
                <span></span> Central
              </div>
            }
            time="2022"
            content="electronic phonebook "
          />
          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-certificate mr-2 hidden md:block text-4xl"></i>{" "}
                <span></span> Kpsolla
              </div>
            }
            time="dec 2021"
            content="kpsolla educational platform and professional training was launched."
          />
          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-bolt mr-2 hidden md:block text-4xl"></i>{" "}
                <span></span> Electricity/Solar
              </div>
            }
            time="Sep 2021"
            content="Utilizing IoT in solar energy production includes sensors attached to the transmission, generation, and distribution equipment to observe and manage businesses from a distance without physical contact. "
          />
          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-school mr-2 hidden md:block text-4xl"></i>{" "}
                <span></span> Education
              </div>
            }
            time="Sep 2020"
            content={
              <ul style={{ listStyle: "inside" }}>
                <li>Access to education is a right, not a privilege. </li>
                <li>
                  current eLearning solution plays a pivotal role in access to
                  education resources. “However! ………..”“More than 50% of
                  populations in Africa do not broadband internet”
                </li>
                <li>
                  In Oxygen, we developed a very successful offline learning
                  solution (without internet) that enable learners to attend
                  course sessions, without need to have internet and high end
                  terminal (smart phone/PC)
                </li>
                <li>
                  Currently we’ve more than 1Million subscribers in different
                  learning topics (Italian language, English, French, etc. …)
                </li>
              </ul>
            }
          />
          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-capsules hidden md:block text-4xl mr-2"></i>{" "}
                <span>Medicine PRM Solution</span>
              </div>
            }
            content="In Oxygen, we developed a cloud based ERP systems customized for the medical sector. The service of the system (ODENTS) offer as a service for small and medium clinics and hospital with monthly or yearly subscription"
            time="Feb.2015"
          />

          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-bullhorn mr-2 hidden md:block text-4xl"></i>{" "}
                <span>Advertisment</span>
              </div>
            }
            time="Jan 2019"
          />
          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-cloud-arrow-up mr-2 hidden md:block text-4xl"></i>{" "}
                <span>Cloud ERP Solution</span>
              </div>
            }
            time="Jan 2013"
            content="In Oxygen, we developed a cloud based ERP systems customized for the medical sector. "
          />
          <TImeCapsule
            title={
              <div
                className="flex items-center"
                style={{ wordWrap: "anywhere" }}
              >
                <i className="fa-solid fa-tower-cell hidden md:block text-4xl mr-2"></i>{" "}
                <span>Telecommunication</span>
              </div>
            }
            time="Feb 2012"
            content="We have over 10 years strategizing and developing innovative digital experiences for corporates and consumers. Oxygen present several SMS and IVR services  in different categories including news sports entertainment human development "
          />

          <TImeCapsule
            title={
              <div className="flex items-center">
                <i className="fa-solid fa-landmark mr-2 hidden md:block text-4xl"></i>{" "}
                <span>Establishment</span>
              </div>
            }
            time="Jan 2012"
            content="Since establishment in 2012, it has achieved many successes and continued rapid growth ever since that date."
          />
        </div>
      </div>
      {/* <ul className="timeline min-h-screen my-[20rem]">
        <TImeCapsule
          direction="direction-r"
          title="establishment"
          time="Jan.2012"
          desc="lorem itemsu donor dolor car cat soemthing place holder"
        />
        <TImeCapsule
          direction="direction-l"
          title="establishment"
          time="Jan.2012"
          desc="lorem itemsu donor dolor car cat soemthing place holder"
        />
        <TImeCapsule
          direction="direction-r"
          title="Cloud ERP Solution"
          time="2012"
          desc="lorem itemsu donor dolor car cat soemthing place holder"
        />
      </ul> */}
    </div>
  );
};

export default History;
