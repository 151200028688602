import { t } from "i18next";
import React, { useState } from "react";

const Article = ({ article, title, short, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <article className="border shadow-indigo-200 shadow-xl mb-8 m-2 p-2 md:max-w-5xl lg:w-[90rem] max-w-md">
      <h2 className="text-3xl font-bold capitalize text-primary">{title}</h2>
      <div className={`readmore ${isExpanded && "expand"}`}>
        {title === "oxygen values" ? (
          <div dangerouslySetInnerHTML={{ __html: short }}></div>
        ) : (
          <div>{short}</div>
        )}
        <div className="flex">
          {title === "oxygen values" ? (
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          ) : (
            <div>{content}</div>
          )}
        </div>
        <span
          className={`readmore-link capitalize text-primary ${
            isExpanded && "expand"
          }`}
          onClick={handleClick}
          style={{ content: t("read-more") }}>
          {isExpanded ? t("read-less") : t("read-more")}
        </span>
      </div>
    </article>
  );
};

Article.prototype = {};

export default Article;
