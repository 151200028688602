import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Services = () => {
  const products = [
    "/Web_Oxy_Call center.jpg",
    "/Web_Oxy_central.jpg",
    "/Web_Oxy_Digital.jpg",
    "/Web_Oxy_ERP.jpg",
    "/Web_Oxy_Kpsolla.jpg",
    "/Web_Oxy_VAS.jpg",
  ];

  const { t } = useTranslation();
  return (
    <div id="services" className="min-h-[60vh]  ">
      <h1 className="capitalize  p-2 mt-10 text-4xl text-center mb-6">
        {t("services")}
      </h1>
      <div className="flex justify-around flex-wrap md:flex-nowrap md:justify-center md:mx-10 ">
        <Link
          to={`/services/education`}
          className="bg-primary w-[40%] flex items-center justify-center mx-5 md:mr-10  text-center mt-5 md:mt-1  hover:ripple-bg-black  text-[#FEC906] font-bold rounded-3xl   capitalize p-2 shadow">
          {t("service-education")}
        </Link>
        <Link
          to={`/services/advertisment`}
          className="bg-primary w-[40%] flex items-center justify-center mx-5 md:mr-10  text-center mt-5 md:mt-1  hover:ripple-bg-black  text-[#FEC906] font-bold rounded-3xl   capitalize p-2 shadow">
          {t("service-advertisment")}
        </Link>
        <Link
          to={`/services/telecommunication`}
          className="bg-primary w-[40%] flex items-center justify-center mx-5 md:mr-10  text-center mt-5 md:mt-1  hover:ripple-bg-black  text-[#FEC906] font-bold rounded-3xl   capitalize p-2 shadow">
          {t("service-telecommunication")}
        </Link>
        <Link
          to={`/services/digital_solutions`}
          className="bg-primary w-[40%] flex items-center justify-center mx-5 md:mr-10  text-center mt-5 md:mt-1  hover:ripple-bg-black  text-[#FEC906] font-bold rounded-3xl   capitalize p-2 shadow">
          {t("service-digital-solutions")}
        </Link>
      </div>
      <div className="flex justify-center  mt-8 ">
        <div className="grid md:grid-cols-3 grid-cols-2 sm:grid-cols-2 gap-5 place-items-center mt-5 mx-5">
          {products.map((x, i) => (
            <div key={i} className="  rounded shadow-sm h-[15rem]">
              <img src={x} alt="" className="w-full h-full rounded shadow-sm" />
            </div>
          ))}
        </div>
        {/* </Slider> */}
      </div>
      {/* <div className="grid grid-cols-2 grid-rows-2 gap-[2rem] place-items-center h-full">
        <Service
          image="./kpsola.png"
          link="https://www.kpsolla.com/"
          desc="kspsolla elearning platform"
        />
        <Service
          image="./forher.png"
          link="https://forher.sd.zain.com/"
          desc="forher women platoform"
        />
      </div> */}
    </div>
  );
};

export default Services;
