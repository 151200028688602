import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const DigitalSolution = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <Header />
      <div className="min-h-[50vh] hero text-indigo-200 flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md">
          <h2
            className=" drop-shadow-2xl  capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            Digital solution
          </h2>
        </div>
      </div>
      <main className=" min-h-screen flex sm:block mt-4 flex-col items-center">
        {/* kspolla */}
        <article className=" mb-8 m-2 p-2 md:max-w-5xl lg:w-[90rem] max-w-md">
          <div className={`readmore tracking-wide ${isExpanded && "expand"}`}>
            <p>
              The volume speed and accessibility of digital technologies has
              significantly altered the way knowledge is communicated and
              consumed, the condition of the rapid growth of the internet use
              which is very influential in human life.
            </p>
            <div className="flex">
              <p>
                Digital portals came into play presenting content in
                entertaining convenient way. Audio and video streaming on demand
                in different categories to target different segments.
              </p>
            </div>
            <span
              className={`readmore-link text-primary ${isExpanded && "expand"}`}
              onClick={handleClick}></span>
          </div>
          <div className="">
            <img src="/R.png" alt="digtial" />
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default DigitalSolution;
