import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const lngs = {
  en: { nativeName: "En" },
  ar: { nativeName: "Ar" },
};

const Dropdown = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="hidden">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!text-white flex items-center !capitalize">
        <span className="mr-2 font-bold">{i18n.language}</span>
        <i className="fa-solid fa-chevron-down"></i>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        classes={{
          paper: "!bg-primary  !text-white",
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {Object.keys(lngs).map((lng, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              i18n.changeLanguage(lng);
              handleClose();
            }}>
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Dropdown;
