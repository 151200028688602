import React from "react";

const Footer = () => {
  return (
    <div className="bg-black text-white flex justify-center py-2 mt-auto  w-full">
      <div className="flex items-center child:text-sm">
        <p>Powered by Oxygen Ict LLc</p>
        <div className="h-5 w-[1px] mx-2  bg-white " />
        <p>All rights reserved &copy;2022</p>
      </div>
    </div>
  );
};

export default Footer;
