import React from "react";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <div className="max-h-[50vh]  mb-8 p-12  m-auto ">
      <h1 className="text-2xl text-center font-bold mb-6">{t("partners")}</h1>
      <div className="flex  items-center child:mr-4 h-60 overflow-x-scroll overflow-y-hidden py-8 ">
        <div className=" w-20 rounded  min-w-[150px] my-0 mx-auto h-full ">
          <img src="./partners/image001.png" alt="" className="w-full " />
        </div>
        <div className="w-20 rounded  min-w-[150px] my-0 mx-auto h-full">
          <img src="./partners/image008.png" alt="" className="w-full " />
        </div>
        <div className="w-20 rounded  min-w-[150px] my-0 mx-auto h-full">
          <img src="./partners/image010.png" alt="" className="w-full " />
        </div>
        <div className="w-20 rounded  min-w-[150px] my-0 mx-auto h-full">
          <img src="./partners/image011.jpg" alt="" className="w-full " />
        </div>
        <div className="w-20 rounded  min-w-[150px] my-0 mx-auto h-full">
          <img src="./partners/image003.jpg" alt="" className="w-full " />
        </div>
        <div className="w-20 rounded  min-w-[150px] my-0 mx-auto h-full">
          <img src="./partners/image004.jpg" alt="" className="w-full " />
        </div>
      </div>
    </div>
  );
};

export default Partners;
