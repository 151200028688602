import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Advertisment = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <Header />
      <div className="min-h-[50vh] hero text-indigo-200 flex justify-center items-center">
        <div className="text-xl md:max-w-screen-md">
          <h2
            className=" drop-shadow-2xl capitalize text-2xl animate-fade  md:text-5xl "
            style={{ textShadow: "white 1px 0 10px" }}>
            Advertisment
          </h2>
        </div>
      </div>
      <main className=" min-h-screen flex sm:block mt-4 flex-col items-center">
        {/* Central */}
        <article className="border shadow-indigo-200 shadow-xl mb-8 m-2 p-2 md:max-w-5xl lg:w-[90rem] max-w-md">
          <h2 className="text-3xl font-bold capitalize text-primary">
            Central
          </h2>

          <div className={`readmore tracking-wide ${isExpanded && "expand"}`}>
            <p>Central is the next step forward</p>
            <div className="flex">
              <p>
                Central is a communication service that lets you connect with
                every service.Central also offers phone book service listing of
                telephone subscribers  in a geographical area or subscribers Its
                purpose is to allow the telephone number of a subscriber
                identified by name and number to be found. The advent of the
                Internet and smart phones  in the 21st century greatly reduced
                the need for a paper phone book.
              </p>
            </div>
            <span
              className={`readmore-link text-primary ${isExpanded && "expand"}`}
              onClick={handleClick}></span>
          </div>
          <div>
            <img src="/central.png" alt="central" />
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default Advertisment;
